export default {
  // 保存当前菜单栏的路径
  savePath(state, pathName) {
    state.pathName = pathName;
  },
  saveToken(state,token){
    state.token = token;
  },
  saveUserData(state,userData){
    state.userData = userData;
  }
};
