const frame = { template: "<router-view></router-view>" };

export default {
  path: "qj",
  component: frame,
  children: [
    {
      path: "/qj/:val",
      name: "ganjin",
      component: () => import("../pages/ganjin/ganjin.vue"),
    },
    {
      path: "/ganjin",
      name: "ganjin_index",
      component: () => import("../pages/ganjin/ganjin_index.vue"),
    }
  ],
};
