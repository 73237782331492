var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden","display":"flex","flex-direction":"column"},attrs:{"id":"app"}},[_c('Navbar',{directives:[{name:"show",rawName:"v-show",value:((
                _vm.path=== '/myCardShare' || 
                _vm.path=== '/' ||
                _vm.path=== '/information' ||
                _vm.path=== '/AboutWDOM' ||
                _vm.path=== '/ProductList' ||
                _vm.path=== '/ProductDetails' ||
                _vm.path=== '/QualityWDOM' 
                )),expression:"(\n                path=== '/myCardShare' || \n                path=== '/' ||\n                path=== '/information' ||\n                path=== '/AboutWDOM' ||\n                path=== '/ProductList' ||\n                path=== '/ProductDetails' ||\n                path=== '/QualityWDOM' \n                )"}]}),_c('div',{staticStyle:{"overflow":"auto","height":"100%"},attrs:{"id":"scrolldiv"}},[_c('router-view'),_c('div',{staticClass:"container"},[_c('myFooter',{directives:[{name:"show",rawName:"v-show",value:((
                _vm.path=== '/' ||
                _vm.path=== '/information' ||
                _vm.path=== '/AboutWDOM' ||
                _vm.path=== '/ProductList' ||
                _vm.path=== '/ProductDetails' ||
                _vm.path=== '/QualityWDOM' 
                )),expression:"(\n                path=== '/' ||\n                path=== '/information' ||\n                path=== '/AboutWDOM' ||\n                path=== '/ProductList' ||\n                path=== '/ProductDetails' ||\n                path=== '/QualityWDOM' \n                )"}]})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }