const frame = { template: "<router-view></router-view>" };

export default {
  path: "homePage",
  component: frame,
  children: [
    {
      path: "/information",
      name: "information",
      component: () => import("../pages/information.vue"),
    },
    {
      path: "/AboutWDOM",
      name: "AboutWDOM",
      component: () => import("../pages/AboutWDOM.vue"),
    },
    {
      path: "/ProductList",
      name: "ProductList",
      component: () => import("../pages/product/product-list.vue"),
    },
    {
      path: "/ProductDetails",
      name: "ProductDetails",
      component: () => import("../pages/product/product-details.vue"),
    },
    {
      path: "/QualityWDOM",
      name: "QualityWDOM",
      component: () => import("../pages/QualityWDOM.vue"),
    },
    {
      path: "/myCardShare",
      name: "myCardShare",
      component: () => import("../pages/ShareWDOM.vue"),
    },
  ],
};
