<template>
    <div id="app" style="overflow:hidden;display: flex; flex-direction: column;">
        <Navbar v-show="(
                    path=== '/myCardShare' || 
                    path=== '/' ||
                    path=== '/information' ||
                    path=== '/AboutWDOM' ||
                    path=== '/ProductList' ||
                    path=== '/ProductDetails' ||
                    path=== '/QualityWDOM' 
                    )"></Navbar>
        <div id="scrolldiv" style="overflow:auto;height: 100%;">
            <router-view />
            <div class="container">
                <myFooter v-show="(
                    path=== '/' ||
                    path=== '/information' ||
                    path=== '/AboutWDOM' ||
                    path=== '/ProductList' ||
                    path=== '/ProductDetails' ||
                    path=== '/QualityWDOM' 
                    )"></myFooter>
            </div>
        </div>

    </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import myFooter from "@/components/myFooter";
export default {
    name: "App",
    data() {
        return {
            path: "",
        };
    },
    mounted() {
        this.path = this.$route.path;
        this.totop();
    },
    watch: {
        $route(to) {
            this.path = to.path;
        },
        pathName() {
            this.totop();
        },
    },
    components: {
        Navbar,
        myFooter,
    },
    methods: {
        // divonscroll(e){
        //     console.log(e);
        // },
        totop() {
            document.getElementById("scrolldiv").scrollTop = 0;
        },
    },
    computed: {
        pathName() {
            return this.$store.state.pathName;
        },
    },
};
</script>

<style>
/* @import url("../src/assets/css/resetCss.css"); */
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
    width: 100%;
}
</style>
