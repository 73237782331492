const frame = { template: "<router-view></router-view>" };

export default {
  path: "auth",
  component: frame,
  children: [
    {
      path: "/auth/:val",
      name: "auth",
      component: () => import("../pages/auth/QueryResultNew.vue"),
    }
  ],
};
