const frame = { template: "<router-view></router-view>" };

export default {
  path: "accredit",
  component: frame,
  children: [
    {
      path: "/accreditLogin",
      name: "accreditLogin",
      component: () => import("PAGES/accredit/login.vue"),
    },
    {
      path: '/accreditList',
      name: 'accreditList',
      meta: { requireAuth: true },
      component: () => import("PAGES/accredit/accreditList.vue")
    },
    {
      path: '/accreditEdit',
      name: 'accreditEdit',
      meta: { requireAuth: true },
      component: () => import("PAGES/accredit/accreditEdit.vue")
    },
    {
      path: '/sampleShow',
      name: 'sampleShow',
      meta: { requireAuth: true },
      component: () => import("PAGES/accredit/sampleShow.vue")
    },
    {
      path: '/accreditCanvas',
      name: 'accreditCanvas',
      meta: { requireAuth: true },
      component: () => import("PAGES/accredit/accreditCanvas.vue")
    },
  ],
};
