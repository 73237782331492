import Vue from "vue";
import VueRouter from "vue-router";
import store from "../vuex/store/index";
import accreditRoute from "./accredit-route";
import homePageRoute from "./homePage-route";
import ganjinRoute from "./ganjin-route";
import authRoute from "./auth-route";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../pages/Home.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../pages/test.vue"),
  },
  homePageRoute,
  accreditRoute,
  ganjinRoute,
  authRoute
];

const index = new VueRouter({
  //mode:'history',
  routes,
  scrollBehavior(to) {
    store.commit("savePath", to.path);
    // if (savedPosition) {
    //     return savedPosition
    // } else {
    //     return { x: 0, y: 0 }
    // }
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

index.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (sessionStorage.getItem("token")) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

export default index;
