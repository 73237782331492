import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './vuex/store/index'
import ElementUI from 'element-ui'
import moment from 'moment' //时间戳转换
import './assets/icons'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
Vue.filter('formatDate', function(dates, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dates).format(pattern)
})
Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
